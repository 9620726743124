export const DAYS = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
export const MONTHS = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export function formatDate(input: string) {
  const date = new Date(input);
  const now = new Date();
  const dayOfWeek = DAYS[date.getDay()];
  const month = MONTHS[date.getMonth()];
  const day = date.getDate();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return (
    <span>
      {dayOfWeek}, {day} de {month} {now.getFullYear() === date.getFullYear() ? null : date.getFullYear()} — {hours}h
      {minutes}
    </span>
  );
}

// export function formatDay(input: string) {
//   const date = new Date(input);
//   const now = new Date();
//   const dayOfWeek = DAYS[date.getDay()];
//   const month = MONTHS[date.getMonth()];
//   const day = date.getDate();

//   if (now.getFullYear() === date.getFullYear()) {
//     return `${day} de ${month} (${dayOfWeek})`;
//   }

//   return `${day} de ${month} de ${date.getFullYear()} (${dayOfWeek})`;
// }

// export function getYesterdayDate() {
//   const today = new Date();
//   const yesterday = new Date(today);
//   yesterday.setDate(today.getDate() - 1);

//   const year = yesterday.getFullYear();
//   const month = String(yesterday.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
//   const day = String(yesterday.getDate()).padStart(2, "0");

//   return `${year}-${month}-${day}`;
// }

// export function getTodayDate() {
//   const today = new Date();
//   const year = today.getFullYear();
//   const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
//   const day = String(today.getDate()).padStart(2, "0");

//   return `${year}-${month}-${day}`;
// }

export function formatSessionDateTime(dateTimeString: string | Date): string {
  // Ensure we have a Date object. If a string is passed, create a Date from it.
  // IMPORTANT: new Date() parsing can be inconsistent. If Prisma gives you a Date object, prefer passing that directly.
  // If it's a string like "2025-04-01T19:00:00.000Z", new Date() will correctly parse it as UTC.
  const date = typeof dateTimeString === "string" ? new Date(dateTimeString) : dateTimeString;

  // Use UTC methods to get the date/time components, ignoring local timezone
  const year = date.getUTCFullYear();
  const monthIndex = date.getUTCMonth(); // 0-indexed (0 for January)
  const day = date.getUTCDate();
  const dayOfWeekIndex = date.getUTCDay(); // 0-indexed (0 for Sunday)
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  // Ensure MONTHS and DAYS arrays are defined correctly
  const month = MONTHS[monthIndex] || `Month ${monthIndex + 1}`; // Fallback
  const dayOfWeek = DAYS[dayOfWeekIndex] || `Day ${dayOfWeekIndex}`; // Fallback

  return `${year} ${month} ${day}, ${dayOfWeek} — ${hours}:${minutes}`;
}

export function getFormatedHour(date: Date) {
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}h${minutes}`;
}

export function getFormatedDayHour(dateTimeString: string | Date): string {
  const date = typeof dateTimeString === "string" ? new Date(dateTimeString) : dateTimeString;

  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const dayOfWeek = DAYS[date.getUTCDay()];
  const shortMonth = (MONTHS[monthIndex] || "???").substring(0, 3);

  return `${dayOfWeek}, ${day} de ${shortMonth} — ${hours}h${minutes}`;
}

export function getFormatedDate(date: Date) {
  const weekDay = DAYS[date.getDay()];
  const day = date.getDate();
  const month = MONTHS[date.getMonth()];

  return `${day} de ${month}`;
}
